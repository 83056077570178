import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useFeatureFlag from "hooks/useFeatureFlag";
import { BuildingFormOnchange } from "./GeneralInformation";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";
import { IFC_CLASSES } from "utils";
import DropdownCheckbox, { DropdownOption } from "components/DropdownCheckbox";

export default observer(function IfcSettings({
  handleChange,
}: BuildingFormOnchange) {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();
  if (!isFeatureVisible("ifc_import_settings")) return <></>;

  const onSelect = (selectedItems: DropdownOption[], name?: string) => {
    handleChange(selectedItems.map((rec) => rec.name).join(","), name);
  };

  const getCheckedItems = () => {
    const currentItems =
      buildingSettingsStore.buildingFormDetails.ifc_parser_split_objects_list;
    return IFC_CLASSES.filter((rec) => currentItems?.includes(rec.id));
  };

  return (
    <div className="relative">
      <div className="text-xl font-semibold mt-5 border-t pt-4 mb-2">
        {t("addBuilding.IfcSettings")}
      </div>
      <div className="text-gray-700 text-sm font-normal mb-6">
        {t("addBuilding.IfcSettingsSubtitle")}
      </div>
      <div className="text-base text-gray-900 font-semibold mb-3">
        {t("addBuilding.multiLayeredComponents")}
      </div>
      <DropdownCheckbox<DropdownOption>
        items={IFC_CLASSES}
        displayKey="name"
        handleSelect={onSelect}
        checkedItems={getCheckedItems()}
        labelName={t("addBuilding.IfcClasses")}
        showSelected
        keepOpen
        hideOptionsSearch
        name="ifc_parser_split_objects_list"
      />
      <div className="text-sm font-normal text-gray-500">
        {t("addBuilding.ifcClassesSelectDesc")}
      </div>
    </div>
  );
});
