import React from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { InventoryProductOut } from "api-client";
import { useTranslation } from "react-i18next";
import { MaterialFileObject } from "store/IfcMapping/types";
import clsx from "clsx";
import DetailsMappingChip from "./DetailsMappingChip";
import DMEMappingChip from "../DynamicMappingEditor/DMEMappingChip";
import Tooltip from "components/Tooltip";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";

export default function MappedProduct({
  object,
  product,
  onClickSearch,
  unmatch,
  label,
  showTooltip = true,
  className,
  inputClassName,
  isDynamicME,
}: Readonly<{
  object?: MaterialFileObject;
  product?: InventoryProductOut | null;
  onClickSearch: () => void;
  unmatch?: () => Promise<void>;
  label?: string;
  showTooltip?: boolean;
  className?: string;
  inputClassName?: string;
  isDynamicME?: boolean;
}>) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const undoMatch = async () => {
    setLoading(true);
    await dynamicMEStore.detailViewMapping(null);
    setLoading(false);
  };

  return (
    <div
      className={clsx(
        "border-b border-gray-300 mt-1",
        "last-of-type:my-0 last-of-type:border-b-0",
        className
      )}
    >
      <div className="flex text-sm">
        {label ?? t("mapping.mappedProduct")}
        {showTooltip && <Tooltip content={t("mapping.EPDInfoText")} />}
      </div>
      <div
        className={clsx(
          "flex cursor-pointer justify-between items-center border mt-1 py-1",
          "px-2 rounded-md border-gray-300 hover:border-indigo-600",
          inputClassName
        )}
        onClick={onClickSearch}
        onKeyDown={onClickSearch}
      >
        {isDynamicME && product?.id ? (
          <DMEMappingChip
            product={product}
            unmatch={undoMatch}
            className="max-w-[155px]"
            loading={loading}
            setLoading={setLoading}
          />
        ) : product?.epds?.[0]?.epd?.url ? (
          <DetailsMappingChip
            object={object as unknown as MaterialFileObject}
            product={product}
            loading={loading}
            unmatch={unmatch}
            className="max-w-[155px]"
            setLoading={setLoading}
          />
        ) : (
          <div className="text-xs font-medium text-gray-400">
            {t("mapping.searchAndMap")}
          </div>
        )}
        <SearchIcon
          width={20}
          className="mx-1 cursor-pointer h-11 text-gray-500 hover:text-black"
        />
      </div>
    </div>
  );
}
