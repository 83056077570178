import { OperationType } from "api-client";
import React from "react";
import { useParams } from "react-router";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { searchStore } from "store/IfcMapping/SearchStore";

const useBuildActionBar = () => {
  const { ifc_id } = useParams();

  const handleOpenProductSearch = () => {
    dynamicMEStore.setCurrentRow(undefined);
    dynamicMEStore.setMatchForSelectedItems(true);
    searchStore.setOpenProductSearch(true);
  };

  const handleAutoMapp = async (mode: OperationType) => {
    if (dynamicMEStore.selectAllChecked) {
      await dynamicMEStore.runFileOperation(ifc_id, mode);
      await dynamicMEStore.fetchObjectsList(0, true, ifc_id);
      dynamicMEStore.setSelectAllChecked(false);
      dynamicMEStore.setSelectedRows([]);
    } else {
      await dynamicMEStore.runSelectedObjectOperations(ifc_id, mode);
    }
  };

  return {
    handleOpenProductSearch,
    handleAutoMapp,
  };
};

export default useBuildActionBar;
