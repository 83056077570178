import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import SortMenu from "./SortMenu";
import { PropertyBrowserTreeNode } from "api-client";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import SimpleColumnsMenu from "./SimpleExistingMenu";
import { useTranslation } from "react-i18next";
import { EMPTY_SORT_ROW } from "../../const";

export interface SortProps {
  id: string;
  property: PropertyBrowserTreeNode | undefined;
  sort: string;
}

export default observer(function SortByPropertyBrowser() {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const openIconRef = React.useRef<HTMLDivElement>(null);
  const selectedPropertiesKeys = new Set(
    dynamicMEStore.selectedSortedByItems.map(
      (item) => item.property?.column_key
    )
  );

  const filteredItems = dynamicMEStore.visibleColumnProperties.filter(
    (item) => !selectedPropertiesKeys.has(item.column_key)
  );
  const closeView = () => dynamicMEStore.setOpenSort(false);

  React.useEffect(() => {
    document.addEventListener("click", closeView);
    return () => {
      document.removeEventListener("click", closeView);
    };
  }, []);

  const onOpenMenu = () => {
    dynamicMEStore.setOpenSort(!dynamicMEStore.openSort);
  };

  const addFirstSortRule = (value: PropertyBrowserTreeNode) => {
    dynamicMEStore.setSelectedSortedByItems([EMPTY_SORT_ROW]);
    onChangeProperty(value, 0, true);
  };

  const onChangeProperty = (
    value: PropertyBrowserTreeNode,
    index: number,
    openMenu?: boolean
  ) => {
    const rows = [...dynamicMEStore.selectedSortedByItems];
    rows[index].property = value;
    dynamicMEStore.setSelectedSortedByItems([...rows]);
    openMenu && dynamicMEStore.setOpenSort(true);
  };

  const clientRect = openIconRef.current?.getBoundingClientRect();

  return (
    <div className="overflow-visible" onClick={(e) => e.stopPropagation()}>
      <div className="relative" ref={openIconRef}>
        {!dynamicMEStore.validSortingRules?.length ? (
          <SimpleColumnsMenu<PropertyBrowserTreeNode>
            isEng={isEng}
            handleSelect={(value) => addFirstSortRule(value)}
            items={filteredItems}
            placeholder={t("mappingEditor.findProperty")}
            icon={<IconPlus className="h-4 w-4 cursor-pointer" />}
            isOpen={(open: boolean) => dynamicMEStore.setOpenSort(open)}
          />
        ) : (
          <IconChevronDown
            className={clsx(
              "h-5 w-5 cursor-pointer",
              dynamicMEStore.openSort ? "rotate-180" : ""
            )}
            onClick={onOpenMenu}
          />
        )}
      </div>
      {dynamicMEStore.openSort && dynamicMEStore.validSortingRules?.length ? (
        <SortMenu
          cursorPosition={{
            x: Number(clientRect?.x),
            y: Number(clientRect?.y),
          }}
          items={filteredItems}
          onChangeProperty={onChangeProperty}
        />
      ) : null}
    </div>
  );
});
