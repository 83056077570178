import { Cell, Row } from "@tanstack/react-table";
import React from "react";

export interface ContextMenuState {
  x: number;
  y: number;
  cell?: Cell<unknown, unknown>;
  row?: Row<unknown>;
}

const useContextMenu = () => {
  const [menu, setMenu] = React.useState<ContextMenuState | null>(null);

  const handleCellRightClick = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    cell: Cell<unknown, unknown>,
    row: Row<unknown>
  ) => {
    event.preventDefault();
    setMenu({
      x: event.clientX,
      y: event.clientY,
      cell,
      row,
    });
  };

  const closeContextMenu = () => setMenu(null);

  React.useEffect(() => {
    document.addEventListener("click", closeContextMenu);
    return () => {
      document.removeEventListener("click", closeContextMenu);
    };
  }, []);

  return {
    menu,
    handleCellRightClick,
    closeContextMenu,
  };
};

export default useContextMenu;
