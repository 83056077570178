import React from "react";
import { useTranslation } from "react-i18next";
import ListSectionHeader from "./ListSectionHeader";
import { CIRCULARITY_DOCUMENT_URL } from "utils";
import { Circularity, SingularCircularityValue } from "api-client";
import { observer } from "mobx-react-lite";

interface Props {
  circularity: Circularity | null | undefined;
}

const CircularityListItem = ({ circularity }: Props) => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const itemSingularElement = (item: SingularCircularityValue) => {
    const field_name = isEng
      ? item.field_name
      : item.field_name_de ?? item.field_name;

    return (
      <li
        key={field_name}
        className={`border-b text-sm font-medium border-gray-300 text-gray-600 p-2 flex justify-between items-center`}
      >
        {field_name}
        <div className="text-sm font-normal text-right max-w-[200px]">
          {item.value ?? ""}
        </div>
      </li>
    );
  };

  return (
    <>
      <ListSectionHeader
        title={t("detailView.reportsTab.circularity")}
        tooltip={
          <div
            dangerouslySetInnerHTML={{
              __html: t("detailView.reportsTab.circularityTooltip", {
                link: CIRCULARITY_DOCUMENT_URL,
              }),
            }}
          ></div>
        }
      />
      <ul className="border border-gray-300 rounded-md p-0 ">
        {circularity?.singular_values?.map(
          (item) => item.value && itemSingularElement(item)
        )}
      </ul>
    </>
  );
};

export default observer(CircularityListItem);
