import React from "react";
import Modal, { ModalProps } from "components/Modal";
import {
  ComponentDetailsByFileOut,
  InventoryProductDetailsByFileOut,
  ProductDisabledStatusEnum,
} from "api-client";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Alert from "components/Alert";
import { userStore } from "store/UserStore";
import { useComplianceInfo } from "hooks/useComplianceInfo";
import { IconCube, IconStack2 } from "@tabler/icons-react";
import ComponentOverview from "./ComponentOverview";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/IfcMapping/SearchStore";
import ComponentDetails from "./ComponentDetails";
import ProductDetails from "./ProductDetails";
import CommonDetails from "./CommonDetails";

interface ProductDetailsPopupProps extends ModalProps {
  productId?: string | null;
  productType?: string | null;
  productName?: string | null;
}

function ProductDetailsPopup(props: ProductDetailsPopupProps) {
  const { getCurrentCertification, getCurrentConformity } = useComplianceInfo();
  const { t } = useTranslation();
  const { ifc_id } = useParams();
  const { authHeader } = userStore;
  const [details, setDetails] = React.useState<
    InventoryProductDetailsByFileOut | ComponentDetailsByFileOut
  >();

  const isComponent = props?.productType == "COMPONENT";

  async function getDetails() {
    if (!props?.productId || !ifc_id || !authHeader) return;
    const fetchedData =
      props?.productType === "PRODUCT"
        ? await searchStore.getProductDetails(props?.productId, ifc_id)
        : await searchStore.getComponentDetails(props?.productId, ifc_id);
    if (fetchedData) {
      setDetails(fetchedData);
      searchStore.setCurrentDetailNode(
        fetchedData as ComponentDetailsByFileOut
      );
    }
  }

  React.useEffect(() => {
    if (props.open) {
      getDetails();
    } else if (props?.productId === details?.id) {
      searchStore.setCurrentDetailNode();
      setDetails(undefined);
    }

    return () => {
      setDetails(undefined);
    };
  }, [props.open]);

  const checkComponentForAlertsRecursively = (
    item: ComponentDetailsByFileOut
  ) => {
    let hasAlert: boolean =
      item?.product_disabled_status != ProductDisabledStatusEnum.UpToDate ||
      false;
    if (hasAlert) return true;
    item.children?.forEach((rec) => {
      if (rec.product_disabled_status != ProductDisabledStatusEnum.UpToDate) {
        hasAlert = true;
      }
      if (rec.children?.length) checkComponentForAlertsRecursively(rec);
    });
    return hasAlert;
  };

  const isCompliant = searchStore.currentDetailNode?.is_compliant_by_file;

  const hasWarnings =
    !isCompliant ||
    searchStore.currentDetailNode?.product_disabled_status !=
      ProductDisabledStatusEnum.UpToDate ||
    checkComponentForAlertsRecursively(details as ComponentDetailsByFileOut);

  const isOverview =
    isComponent && searchStore.currentDetailNode?.id === details?.id;

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.close}
      leadingIcon={
        props?.productType === "COMPONENT" ? (
          <IconStack2
            stroke={3}
            className="bg-cyan-300 rounded-full p-[5px] w-6 h-6 mr-2 text-cyan-900"
          />
        ) : (
          <IconCube
            stroke={3}
            className="bg-teal-300 rounded-full p-[5px] w-6 h-6 mr-2 text-teal-900"
          />
        )
      }
      title={props?.productName as string}
      containerClassName="min-w-[1376px]"
      closeButton
      disableOutsideClick
      position="top"
    >
      {details ? (
        <div className="flex max-h-[calc(100vh-140px)]">
          {isComponent ? (
            <ComponentOverview
              details={details as ComponentDetailsByFileOut}
              hasWarnings={hasWarnings}
            />
          ) : null}

          <div className="p-6 w-full overflow-y-auto">
            {isComponent ? (
              <div className="text-lg font-semibold mb-6">
                {isOverview
                  ? t("mapping.overview")
                  : searchStore.currentDetailNode?.name}
              </div>
            ) : null}

            {isComponent && isOverview && hasWarnings ? (
              <Alert
                description={t("mapping.componentAlertOverview")}
                className="mb-2"
              />
            ) : null}

            {!isCompliant && !isOverview ? (
              <Alert
                type="attention"
                description={t(
                  searchStore.openProductSearch
                    ? "mapping.notComplianceAlert"
                    : "mapping.notComplianceAlertME",
                  {
                    conformity: getCurrentConformity(),
                    certification: getCurrentCertification()?.name,
                  }
                )}
                className="mb-2"
              />
            ) : null}

            {searchStore.currentDetailNode?.product_disabled_status ===
              "DISABLED" && (
              <Alert
                type="attention"
                description={t("mapping.productDisabledAlert", {
                  conformity: getCurrentConformity(),
                  certification: getCurrentCertification()?.name,
                })}
                className="mb-2"
              />
            )}

            {searchStore.currentDetailNode?.product_disabled_status ===
              "UPDATE_AVAILABLE" && (
              <Alert
                type="attention"
                description={t("mapping.updatedProductAlert", {
                  conformity: getCurrentConformity(),
                  certification: getCurrentCertification()?.name,
                })}
                className="mb-2"
              />
            )}

            <CommonDetails className={hasWarnings ? "mt-6" : ""} />
            {isComponent ? (
              <ComponentDetails />
            ) : (
              <ProductDetails epdGroupsData={details.epd_groups} />
            )}
          </div>
        </div>
      ) : (
        skeleton()
      )}
    </Modal>
  );
}

export default observer(ProductDetailsPopup);

function skeleton() {
  return (
    <div className="animate-pulse p-6">
      <div className="h-20 w-full bg-gray-200 rounded-lg dark:bg-gray-700 mb-2.5"></div>
      <div className="h-20 w-full bg-gray-200 rounded-lg dark:bg-gray-700 mb-2.5"></div>
      <div className="h-20 w-full bg-gray-200 rounded-lg dark:bg-gray-700 mb-2.5"></div>
    </div>
  );
}
