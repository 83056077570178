import React from "react";
import { observer } from "mobx-react-lite";
import { IconPlus, IconSettings } from "@tabler/icons-react";
import SelectorMenu from "./SelectorMenu";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import DropdownBrowser from "components/DropdownBrowser";
import { useTranslation } from "react-i18next";
import { PropertyBrowserTreeNode } from "api-client";
import clsx from "clsx";
import { COLUMNS_GROUPS } from "../DMETableView/useTableColumns";

export default observer(function ColumnSelectorBrowser(props: {
  groupName: string;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const filteredItems = dynamicMEStore.filterNotSelectedItems(
    dynamicMEStore.properties,
    props.groupName === COLUMNS_GROUPS.infoColumns
      ? dynamicMEStore.visibleColumnProperties
      : dynamicMEStore.selectedGroupByItems
  );

  const onAddColumn = async (values: PropertyBrowserTreeNode[]) => {
    if (props.groupName === COLUMNS_GROUPS.groupingColumns) {
      dynamicMEStore.setSelectedGroupByItems(values);
      dynamicMEStore.reOrderGroupingColumns();
    } else {
      dynamicMEStore.setVisibleColumnProperties([
        ...dynamicMEStore.selectedGroupByItems,
        ...values,
      ]);
    }
    closeView();
  };

  const closeView = () =>
    dynamicMEStore.setOpenColumnSettings({
      ...dynamicMEStore.openColumnSettings,
      open: false,
    });

  React.useEffect(() => {
    document.addEventListener("click", closeView);
    return () => {
      document.removeEventListener("click", closeView);
    };
  }, []);

  const handleOnClick = (e: { clientX: number; clientY: number }) => {
    dynamicMEStore.setOpenColumnSettings({
      groupName: props.groupName,
      open:
        props.groupName != dynamicMEStore.openColumnSettings.groupName
          ? true
          : !dynamicMEStore.openColumnSettings.open,
      positionX: e.clientX,
      positionY: e.clientY,
    });
  };

  const onOpenPropertyBrowser = (open: boolean) =>
    dynamicMEStore.setOpenAddColumns(open);

  const selectedItems =
    COLUMNS_GROUPS.infoColumns === props.groupName
      ? dynamicMEStore.visibleColumnProperties
      : dynamicMEStore.selectedGroupByItems;

  return (
    <div
      className={clsx("overflow-visible flex text-gray-900", {
        "text-white": props.groupName === COLUMNS_GROUPS.groupingColumns,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <DropdownBrowser<PropertyBrowserTreeNode>
        displayKey={isEng ? "name" : "name_de"}
        detailKey={isEng ? "classification" : "classification_de"}
        handleSelect={onAddColumn}
        items={filteredItems}
        checkedItems={selectedItems}
        placeholder={t("mappingEditor.findProperty")}
        optionsClassName="!max-w-fit min-w-[246px]"
        containerClassName="!pointer-events-auto"
        fixedPos
        isOpen={onOpenPropertyBrowser}
        titleOrIcon={
          <IconPlus
            className="cursor-pointer"
            stroke={2.5}
            size={18}
            onClick={closeView}
          />
        }
      />
      <IconSettings
        className="cursor-pointer ml-1"
        stroke={2.5}
        size={18}
        onClick={handleOnClick}
      />

      {dynamicMEStore.openColumnSettings.open &&
      dynamicMEStore.openColumnSettings.groupName === props.groupName ? (
        <SelectorMenu
          close={closeView}
          onAddColumn={onAddColumn}
          filteredItems={filteredItems}
          selectedItems={selectedItems}
        />
      ) : null}
    </div>
  );
});
