import React from "react";
import { observer } from "mobx-react-lite";
import ProductsListContainer from "../ProductsListContainer";
import { searchStore } from "store/IfcMapping/SearchStore";
import RowsList, { RowsListProps } from "../SearchRowsList";
import EmptyState from "../EmptyState";
import { ProductSearchOutput } from "api-client";
import { useTranslation } from "react-i18next";
import { IconCopy, IconEdit, IconInfoCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router";
import useComponentActions from "./useComponentActions";
import ProductDetailsPopup from "features/MappingTools/ProductDetailsPopup";
import useFeatureFlag from "hooks/useFeatureFlag";

const DefaultProducts = (props: RowsListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isFeatureVisible } = useFeatureFlag();
  const { saveStateAndGetPath } = useComponentActions();

  function shouldShowEmptyView() {
    return (
      !searchStore.productSearchLoading &&
      searchStore.searchResults.items.length === 0
    );
  }

  const duplicateProduct = (id?: string | null) => {
    const pathname = saveStateAndGetPath();
    navigate(`${pathname}/product-editor`, {
      state: { id: id, compliant: false, mode: "duplicate" },
    });
  };

  const editProduct = (id?: string | null) => {
    const pathname = saveStateAndGetPath();
    navigate(`${pathname}/product-editor`, {
      state: { id: id, compliant: false, mode: "edit" },
    });
  };

  const settingsItems = (product: ProductSearchOutput | null | undefined) => {
    const itemList = [
      {
        name: t("mapping.showDetails"),
        icon: <IconInfoCircle width={18} className="mr-2" />,
        onClick: () =>
          searchStore.setOpenDetails({ open: true, product: product }),
        className: "text-gray-700",
        disabled: false,
      },
      {
        name: t("mapping.duplicateProduct"),
        icon: <IconCopy width={18} className="mr-2" />,
        onClick: () => duplicateProduct(product?.id),
        className: "text-gray-700",
        disabled: false,
      },
    ];

    if (isFeatureVisible("edit_default_components")) {
      itemList.push({
        name: t("mapping.editProduct"),
        icon: <IconEdit width={15} className="mr-2 min-w-[15px]" />,
        onClick: () => editProduct(product?.id),
        className: "text-gray-700",
        disabled: false,
      });
    }
    return itemList;
  };

  return (
    <>
      <ProductsListContainer>
        {shouldShowEmptyView() ? (
          <EmptyState />
        ) : (
          <RowsList
            match={props.match}
            settingsItems={settingsItems}
            settingsDisabled={[false, false]}
          />
        )}
      </ProductsListContainer>
      <ProductDetailsPopup
        productId={searchStore.openDetails.product?.id}
        productName={searchStore.openDetails.product?.name}
        productType={searchStore.openDetails.product?.type}
        open={searchStore.openDetails.open}
        close={() =>
          searchStore.setOpenDetails({ open: false, product: undefined })
        }
      ></ProductDetailsPopup>
    </>
  );
};

export default observer(DefaultProducts);
