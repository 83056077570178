import React from "react";
import {
  IconChevronUp,
  IconLinkOff,
  IconSearch,
  IconSparkles,
} from "@tabler/icons-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { searchStore } from "store/IfcMapping/SearchStore";
import { observer } from "mobx-react-lite";
import BulkActionBarMenu from "./BulkActionBarMenu";
import useBuildActionBar from "./useBuildActionBar";

const BulkActionBar = () => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { handleOpenProductSearch, handleAutoMapp } = useBuildActionBar();

  React.useEffect(() => {
    dynamicMEStore.setOpenBulkActionsMenu(false);
  }, [dynamicMEStore.selectedRows.length === 0]);

  React.useEffect(() => {
    searchStore.openProductSearch === false &&
      dynamicMEStore.setMatchForSelectedItems(false);
  }, [searchStore.openProductSearch]);

  const getSelectedObjectsTotalCount = () => {
    const total = dynamicMEStore.selectedRows
      .map((item) => item.object_count)
      .reduce((acc, value) => Number(acc) + Number(value), 0);
    return total ?? 0;
  };

  const getTitleValues = () => ({
    amount: dynamicMEStore.selectAllChecked
      ? t("mappingEditor.all")
      : dynamicMEStore.selectedRows.length,
    letter: dynamicMEStore.selectedRows.length === 1 ? "" : isEng ? "s" : "e",
  });

  const getGroupingTitleValues = () => ({
    amount: dynamicMEStore.selectAllChecked
      ? t("mappingEditor.all")
      : getSelectedObjectsTotalCount(),
    letter: getSelectedObjectsTotalCount() === 1 ? "" : isEng ? "s" : "e",
    groupCount: dynamicMEStore.selectedRows.length,
    letter2: dynamicMEStore.selectedRows.length === 1 ? "" : isEng ? "s" : "n",
  });

  const openMenu = () =>
    dynamicMEStore.setOpenBulkActionsMenu(!dynamicMEStore.openBulkActionsMenu);

  if (dynamicMEStore.selectedRows.length === 0) return <></>;
  return (
    <div
      className={clsx(
        "h-10 flex items-center justify-center absolute bottom-14 bg-indigo-600 text-sm",
        "shadow-lg w-fit rounded-[20px] text-white text-base z-2 h-10"
      )}
    >
      <div className="flex items-center px-3">
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              dynamicMEStore.hasGrouping()
                ? "mappingEditor.actionBarTextGrouped"
                : "mappingEditor.actionBarText",
              dynamicMEStore.hasGrouping()
                ? getGroupingTitleValues()
                : getTitleValues()
            ),
          }}
        />
        <IconChevronUp className="ml-2 cursor-pointer" onClick={openMenu} />
      </div>
      <div className="text-white flex border-l border-l-white h-full items-center">
        <IconLinkOff
          size={24}
          className={clsx(
            "cursor-pointer hover:bg-indigo-700 p-2 min-h-[40px] min-w-[40px]",
            {
              "cursor-not-allowed pointer-events-none opacity-50l":
                dynamicMEStore.showMappingLoading,
            }
          )}
          onClick={() => handleAutoMapp("undo_match")}
        />
        <IconSparkles
          size={24}
          className={clsx(
            "p-2 cursor-pointer hover:bg-indigo-700 min-h-[40px] min-w-[40px]",
            {
              "cursor-not-allowed pointer-events-none opacity-50":
                dynamicMEStore.showMappingLoading,
            }
          )}
          onClick={() => handleAutoMapp("auto_map_match")}
        />
        <IconSearch
          className="p-2 cursor-pointer hover:bg-indigo-700 min-h-[40px] min-w-[40px] rounded-tr-full rounded-br-full"
          onClick={handleOpenProductSearch}
        />
      </div>
      {dynamicMEStore.openBulkActionsMenu && <BulkActionBarMenu />}
    </div>
  );
};

export default observer(BulkActionBar);
