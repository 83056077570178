import React from "react";
import ListSectionHeader from "./ListSectionHeader";
import { PropertySet } from "api-client";
import { useTranslation } from "react-i18next";
import { CustomCollapse } from "./CustomCollapse";

interface CollapseStates {
  [key: string]: boolean;
}

interface Props {
  allProperties: PropertySet[];
}

const AllPropertiesListItem = ({ allProperties: items }: Props) => {
  const { t } = useTranslation();

  const [collapseStates, setCollapseStates] = React.useState(() => {
    const initialStates: CollapseStates = {};
    items?.forEach((item) => {
      initialStates[item.property_set_name] = item.properties?.length === 0;
    });
    return initialStates;
  });

  const toggleCollapse = (itemId: string) => {
    setCollapseStates((prevStates) => ({
      ...prevStates,
      [itemId]: !prevStates[itemId],
    }));
  };

  const expandAll = () => {
    setCollapseStates(() => {
      const expandedStates: CollapseStates = {};
      items?.forEach((item) => {
        expandedStates[item.property_set_name] = true;
      });
      return expandedStates;
    });
  };

  const collapseAll = () => {
    setCollapseStates(() => {
      const collapsedStates: CollapseStates = {};
      items?.forEach((item) => {
        collapsedStates[item.property_set_name] = item.properties?.length === 0;
      });
      return collapsedStates;
    });
  };

  const itemElement = (key: string, value: string | null | undefined) => {
    return (
      <li
        key={key}
        className={`last:border-b-0 border-b border-b-gray-100 text-sm font-medium border-gray-300 text-gray-600 p-2 flex justify-between items-center`}
      >
        {key}
        <div className="text-sm font-normal max-w-[300px] text-right">
          {value}
        </div>
      </li>
    );
  };

  function checkIsValueValid(value: string | number | null | undefined) {
    if (value === null || value === undefined || value === "") return false;

    if (typeof value === "number" && value) return true;

    return true;
  }

  return (
    <>
      <ListSectionHeader
        showButtons={items.length > 0}
        onExpandAll={expandAll}
        onCollapseAll={collapseAll}
        title={t("detailView.ifcProperties.allProperties")}
      />
      <ul className="border border-gray-300 rounded-md p-0 ">
        {items?.map(
          (item, index) =>
            checkIsValueValid(item.property_set_name) && (
              <CustomCollapse
                isOpen={collapseStates[item.property_set_name]}
                toggleCollapse={() => toggleCollapse(item.property_set_name)}
                key={item.property_set_name}
                title={item.property_set_name}
                isLastIndex={index === items?.length - 1}
              >
                {item.properties?.map(
                  (m) =>
                    checkIsValueValid(m.property_name) &&
                    itemElement(m.property_name, m.property_value as string)
                )}
              </CustomCollapse>
            )
        )}
      </ul>
    </>
  );
};

export default AllPropertiesListItem;
