import React from "react";
import {
  IconChevronRight,
  IconHome,
  IconHomeMove,
  IconLinkOff,
  IconSearch,
  IconSparkles,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useBuildActionBar from "./useBuildActionBar";
import Tooltip from "components/Tooltip";
import useOutsideClick from "hooks/useOutsideClick";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { observer } from "mobx-react-lite";

const IconMaterialFlowAdded = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M14.8333 9H16.5L9 1.5L1.5 9H3.16667V14.8333C3.16667 15.2754 3.34226 15.6993 3.65482 16.0118C3.96738 16.3244 4.39131 16.5 4.83333 16.5H9.41667M6.5 16.5V11.5C6.5 11.058 6.6756 10.6341 6.98816 10.3215C7.30072 10.0089 7.72464 9.83333 8.16667 9.83333H9.83333C10.2754 9.83333 10.6993 10.0089 11.0118 10.3215C11.3244 10.6341 11.5 11.058 11.5 11.5M17.3333 14.8333H12.3333M12.3333 14.8333L14.8333 17.3333M12.3333 14.8333L14.8333 12.3333"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IconMaterialFlowTemp = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M15.4167 7.91667L10 2.5L2.5 10H4.16667V15.8333C4.16667 16.2754 4.34226 16.6993 4.65482 17.0118C4.96738 17.3244 5.39131 17.5 5.83333 17.5H7.5M7.5 17.5H9.58333M7.5 17.5V12.5C7.5 12.058 7.67559 11.634 7.98816 11.3215C8.30072 11.0089 8.72464 10.8333 9.16667 10.8333M15.4169 13.3333L17.8613 15.8333M17.8613 15.8333L15.4169 18.3333M17.8613 15.8333L14.1113 15.8333C13.463 15.8333 12.8413 15.5699 12.3828 15.1011C11.9244 14.6323 11.6669 13.9964 11.6669 13.3333C11.6669 12.6703 11.9244 12.0344 12.3828 11.5656C12.8413 11.0967 13.463 10.8333 14.1113 10.8333H17.0833"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface MenuItem {
  id: string;
  title?: string;
  items?: MenuItem[];
  label?: string;
  icon?: React.ReactNode;
  tooltip?: string;
  children?: MenuItem[];
  detail?: string;
  value?: string;
  onClick?: () => void;
}

const BulkActionBarMenu = ({
  menuItems,
  className,
}: {
  menuItems?: MenuItem[];
  className?: string;
}) => {
  const { t } = useTranslation();
  const { handleOpenProductSearch, handleAutoMapp } = useBuildActionBar();
  const menuRef = React.useRef(null);

  const menu: MenuItem[] = [
    {
      id: "1",
      title: t("mappingEditor.editProductMapping"),
      items: [
        {
          id: "1_1",
          label: t("mappingEditor.manualMapping"),
          icon: <IconSearch size={19} />,
          onClick: () => handleOpenProductSearch(),
        },
        {
          id: "1_2",
          label: t("mappingEditor.undoMapping"),
          icon: <IconSparkles size={19} />,
          onClick: () => handleAutoMapp("auto_map_match"),
        },
        {
          id: "1_3",
          label: t("mappingEditor.autoMapping"),
          icon: <IconLinkOff size={19} />,
          onClick: () => handleAutoMapp("undo_match"),
        },
      ],
    },
  ];

  useOutsideClick(menuRef, () => {
    dynamicMEStore.setOpenBulkActionsMenu(false);
  });

  const currentMenu = menuItems ?? menu;

  return (
    <div
      className={clsx(
        "bg-white absolute bottom-10 border border-gray-300 rounded-md",
        className
      )}
      ref={menuRef}
    >
      {currentMenu.map((group) => (
        <div key={group.id} className="last-of-type:border-t border-gray-300">
          <div
            className={clsx(
              "text-gray-500 bg-gray-50 text-xs font-medium py-1 px-4 rounded-tl-md",
              "rounded-tr-md border-b border-gray-300"
            )}
          >
            {group.title}
          </div>
          {group.items?.map((item) => (
            <div
              className={clsx(
                "flex items-center justify-between text-gray-700 px-4 py-2 group/menu-item",
                "hover:bg-indigo-50 hover:text-indigo-700 cursor-pointer font-medium",
                "last-of-type:rounded-bl-md last-of-type:rounded-br-md whitespace-nowrap"
              )}
              key={item.id}
              onClick={item.onClick}
            >
              <div className="flex items-center">
                {item.icon}
                <span className="ml-2">{item.label}</span>
              </div>
              <div className="flex items-center pl-7">
                {item.tooltip && (
                  <Tooltip
                    iconClassName="opacity-80"
                    content={t("mappingEditor.bulkActionMenuTooltip")}
                  ></Tooltip>
                )}
                {item.detail && (
                  <div className="opacity-80 font-normal">{item.detail}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default observer(BulkActionBarMenu);
