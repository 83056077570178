/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { useVirtual, VirtualItem } from "react-virtual";
import {
  Row,
  Cell,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getSortedRowModel,
  getExpandedRowModel,
  flexRender,
  SortingState,
  VisibilityState,
  ColumnSizingState,
  RowSelectionState,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import ColumnToggleButtons from "./ColumnToggleButtons";
import LinearLoading from "components/LinearLoading";
import Truncate from "components/Truncate";
import HeaderColumn, { isColumnSticky } from "./HeaderColumn";
import { CustomFilterProps, TableProps } from "./types";
import ContextMenu from "./ContextMenu";
import useContextMenu from "./ContextMenu/useContextMenu";

export default function SearchSortTable({
  scrollDivClassName = "",
  data,
  skeletonLoading,
  dataFetchLoading,
  getColumns,
  getRowProps,
  onRowClick,
  renderSubComponent,
  getRowCanExpand,
  fetchData,
  selectedRow,
  borderedStyle,
  customContainerStyle,
  headerRowStyle,
  headerColumnStyle,
  headerContainerStyle,
  headerDivStyle,
  hasGroupHeader,
  customRowStyle,
  customColumnStyle,
  toggleButtons,
  hideHeader,
  hiddenColumns,
  totalDBRowCount,
  fetchSize = 25,
  fullHeightSkeleton,
  skeletonClassName,
  enableColumnResizing,
  zebraStriped,
  rowCountClassName,
  onSortChanged,
  onSizeChanged,
  columnSizingState,
  defaultSort,
  hasHoverStyle,
  enableTooltip,
  enableRowSelection,
  onRowSelect,
  noFooterBar,
  groupHeaderClassName,
  menuOptions,
  selectedRows,
  selectAllChecked,
  columnsOrder,
  currentRowID,
  disableUserInteraction,
  onHoveredRow,
  deviderIndexes,
  customContextMenuComponent,
}: TableProps) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const defaultColumns = getColumns();
  const { t } = useTranslation();
  const [columns, setColumns] = React.useState<typeof defaultColumns>(() => [
    ...defaultColumns,
  ]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnSizing, setColumnSizing] = React.useState<ColumnSizingState>(
    columnSizingState ?? {}
  );
  const columnResizeMode = "onChange";
  const scrollToTop = () => tableScrollRef.current?.scrollTo({ top: 0 });
  const contextMenu = useContextMenu();
  const [hoveredRow, setHoveredRow] = React.useState<Row<unknown> | null>(null);

  useEffect(() => {
    hiddenColumns && setColumnVisibility(hiddenColumns);
  }, [hiddenColumns]);

  useEffect(() => {
    defaultSort && setSorting(defaultSort);
  }, [defaultSort?.length]);

  useEffect(() => {
    defaultColumns && setColumns(defaultColumns);
  }, [defaultColumns]);

  useEffect(() => {
    onHoveredRow?.(hoveredRow);
  }, [hoveredRow]);

  const fetchNextPage = async () =>
    await fetchData?.(totalFetched, fetchSize, false);

  const totalFetched = data?.length ? data.length : 0;

  useEffect(() => {
    if (Number(data?.length) <= fetchSize) scrollToTop();
    if (selectAllChecked && data?.length) {
      const newItems = Array.from({ length: data.length }, (_, i) =>
        String(i)
      )?.reduce((a, v) => ({ ...a, [v]: true }), {});
      setRowSelection(newItems);
    }
  }, [data?.length]);

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement && totalDBRowCount) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollTop + clientHeight >= scrollHeight - 5 &&
          totalDBRowCount !== totalFetched
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, totalFetched, totalDBRowCount]
  );

  const table = useReactTable({
    data: data ?? [],
    columns,
    columnResizeMode,
    state: {
      columnFilters,
      sorting,
      columnVisibility,
      columnSizing,
      rowSelection,
      columnOrder: columnsOrder,
    },
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    onSortingChange: setSorting,
    autoResetAll: true,
    enableColumnResizing,
    manualSorting: !!fetchData,
    enableRowSelection: enableRowSelection, //enable row selection for all rows
    onRowSelectionChange: setRowSelection,
  });

  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const tableScrollRef = React.useRef<HTMLDivElement>(null);
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 500,
  });

  const { virtualItems: virtualRows } = rowVirtualizer;

  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === "name") {
      if (table.getState().sorting[0]?.id !== "name") {
        table.setSorting([{ id: "name", desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);

  React.useEffect(() => {
    if (onSortChanged) {
      onSortChanged(sorting);
      scrollToTop();
    }
  }, [sorting]);

  React.useEffect(() => {
    onRowSelect?.(Object.keys(rowSelection));
  }, [rowSelection]);

  React.useEffect(() => {
    if (selectedRows) {
      const newItems = selectedRows?.reduce(
        (a, v) => ({ ...a, [v]: true }),
        {}
      );
      setRowSelection(newItems);
    }
  }, [selectedRows?.length]);

  React.useEffect(() => {
    if (enableColumnResizing) {
      onSizeChanged?.(columnSizing);
      scrollToTop();
    }
  }, [columnSizing]);

  function headerContent() {
    return (
      <thead
        className={clsx("sticky top-0 bg-gray-50 z-2", headerContainerStyle)}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className={headerRowStyle}>
            {headerGroup.headers.map((header, headerIndex) => {
              return (
                <HeaderColumn
                  key={header.id}
                  table={table}
                  header={header}
                  enableColumnResizing={enableColumnResizing}
                  headerColumnStyle={headerColumnStyle}
                  borderedStyle={borderedStyle}
                  hasGroupHeader={hasGroupHeader}
                  headerDivStyle={headerDivStyle}
                  setColumnSizing={setColumnSizing}
                  groupHeaderClassName={groupHeaderClassName}
                  deviderIndexes={deviderIndexes}
                  headerIndex={headerIndex}
                />
              );
            })}
          </tr>
        ))}
      </thead>
    );
  }

  function bodyContent() {
    return (
      <tbody
        className={clsx("bg-white", {
          "[&>*:nth-child(even)]:bg-gray-50": zebraStriped,
        })}
      >
        {virtualRows.map((virtualRow: VirtualItem, rowIndex) => {
          const row = rows[virtualRow.index];
          getRowProps?.(row);
          const originalItem = row.original as { id: string };
          return bodyRowContent(row, rowIndex, originalItem);
        })}
      </tbody>
    );
  }

  function bodyRowContent(
    row: Row<unknown>,
    rowIndex: number,
    originalItem: { id: string }
  ): React.JSX.Element {
    return (
      <Fragment key={row.id}>
        <tr
          className={clsx(
            "border-gray-300 border-b group/row",
            customRowStyle,
            row.getIsExpanded() ? "border-none bg-gray-50" : "",
            { "cursor-pointer": onRowClick }
          )}
          tabIndex={rowIndex}
          id={originalItem.id}
          onClick={() => !contextMenu.menu?.x && onRowClick?.(row)}
          onMouseEnter={() => onHoveredRow && setHoveredRow?.(row)}
          onMouseLeave={() => onHoveredRow && setHoveredRow?.(null)}
        >
          {row
            .getVisibleCells()
            .map((cell: Cell<unknown, unknown>, cellIndex: number) => {
              const minWidth = (cell.column.columnDef as CustomFilterProps)
                .minWidth;

              return (
                <td
                  key={cell.id}
                  style={{
                    width:
                      cell.column.getSize() < Number(minWidth)
                        ? minWidth
                        : cell.column.getSize(),
                    maxWidth:
                      (cell.column.columnDef as CustomFilterProps).maxWidth ??
                      cell.column.getSize(),
                    minWidth: minWidth ?? "auto",
                  }}
                  onContextMenu={(event) =>
                    contextMenu.handleCellRightClick(event, cell, row)
                  }
                  className={clsx(
                    "text-sm text-gray-500 border-gray-300",
                    {
                      "px-2 py-4": !borderedStyle,
                      "px-4 py-[14px] font-normal text-black": borderedStyle,
                      "border-t": rowIndex,
                      "group relative": enableTooltip,
                      "!border-r-gray-300 border-r":
                        cell.column.id === "select" ||
                        deviderIndexes?.includes(cellIndex),
                      "!bg-indigo-50":
                        hasHoverStyle && currentRowID === originalItem.id,
                      "group-hover/row:!bg-indigo-50": hasHoverStyle,
                    },
                    customColumnStyle,
                    row.getIsExpanded() ? "!border-r-0 !border-l-0" : "",
                    isColumnSticky(
                      cell,
                      row.getIsExpanded() ? "bg-gray-50" : "bg-white"
                    ),
                    (cell.column.columnDef as CustomFilterProps).cellClassName
                  )}
                  onClick={(e) =>
                    (cell.column.columnDef as CustomFilterProps).onClick?.(
                      e,
                      row
                    )
                  }
                >
                  {enableTooltip ? (
                    <Truncate className="max-w-auto">
                      {
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        ) as string
                      }
                    </Truncate>
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              );
            })}
        </tr>
        {row.getIsExpanded() && subTableContent(row)}
      </Fragment>
    );
  }

  function subTableContent(row: Row<unknown>): React.ReactNode {
    return (
      <tr>
        <td colSpan={row.getVisibleCells().length}>
          {renderSubComponent?.({ row })}
        </td>
      </tr>
    );
  }

  function rowCountContent() {
    return (
      <div
        className={clsx(
          "bg-gray-50 h-6 text-gray-600 px-2 py-2 text-xs flex",
          "border-gray-300 items-center relative rounded-b-lg border-t",
          { "border border-t-0": borderedStyle },
          rowCountClassName
        )}
      >
        {dataFetchLoading && <LinearLoading />}
        {data?.length} {t("commons.of")} {totalDBRowCount} {t("commons.rows")}.
      </div>
    );
  }

  const onMouseScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.stopPropagation();
    fetchData && fetchMoreOnBottomReached(e.target as HTMLDivElement);
  };

  function loadingSkeleton() {
    return (
      <div
        className={clsx(
          "animate-pulse p-2.5 max-h-[calc(100vh-250px)] overflow-hidden rounded border border-gray-200",
          skeletonClassName,
          { "bg-white": fullHeightSkeleton }
        )}
      >
        {fullHeightSkeleton ? (
          [...Array(50)].map((_item, index) => (
            <div
              key={index}
              className="w-full h-14 bg-gray-200 dark:bg-gray-700 top mb-1"
            />
          ))
        ) : (
          <div className="w-full h-14 bg-gray-200 dark:bg-gray-700 top mb-1" />
        )}
      </div>
    );
  }

  if (skeletonLoading) {
    return loadingSkeleton();
  }

  return (
    <>
      {toggleButtons && <ColumnToggleButtons table={table} />}
      <div
        className={clsx(
          "bg-gray-50 overflow-auto max-h-[calc(100vh-250px)] border-gray-300 relative flex-grow",
          scrollDivClassName,
          { "border rounded-lg": borderedStyle, "rounded-b-none": fetchData },
          disableUserInteraction && "pointer-events-none"
        )}
        onScroll={onMouseScroll}
        ref={tableScrollRef}
      >
        <div
          ref={tableContainerRef}
          className={clsx("min-w-full border-gray-300", customContainerStyle)}
        >
          <table
            className="min-w-full table-fixed"
            style={{
              borderCollapse: selectedRow ? "collapse" : "separate",
              borderSpacing: 0,
            }}
          >
            {hideHeader ? null : headerContent()}
            {bodyContent()}
          </table>
        </div>
      </div>
      {fetchData && !noFooterBar && rowCountContent()}
      {contextMenu && menuOptions && (
        <ContextMenu
          info={contextMenu.menu}
          items={menuOptions}
          onClose={contextMenu.closeContextMenu}
        />
      )}
      {contextMenu.menu &&
        customContextMenuComponent?.(
          contextMenu.menu,
          contextMenu.closeContextMenu
        )}
    </>
  );
}
