import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UPCOMING_OPERATION_LIST } from "../consts.d";
import Modal from "components/Modal";
import { BuildingOUT, PlaceDetailsOUT } from "api-client";
import { placeDetailsToString } from "../../../utils";
import { observer } from "mobx-react-lite";
import GeneralInformation from "./GeneralInformation";
import PlannedOperation from "./PlannedOperation";
import Compliance from "./ComplianceSettings";
import Footer from "./Footer";
import MappingEditorTile from "./MappingEditorTile";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import useFeatureFlag from "hooks/useFeatureFlag";
import IfcSettings from "./IfcSettings";

export type FormValue =
  | string
  | number
  | undefined
  | File[]
  | Date
  | object
  | boolean;

export type AddEditBuildingForm = Partial<BuildingOUT> & {
  building_type_id?: string;
  string_address?: string;
  selectedImages?: File[];
  certification_id?: string;
  conformity_id?: string;
};

interface Section {
  name: string;
  content: React.ReactNode;
  ref: React.MutableRefObject<null | HTMLLIElement>;
}

const AddEditBuilding = observer(
  ({
    open,
    close,
    type,
    building,
    onMaxLimitWarning,
  }: {
    open: boolean;
    close: () => void;
    type: "add" | "edit";
    building?: BuildingOUT;
    onMaxLimitWarning?: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const { t } = useTranslation();
    const containerRef = React.useRef<HTMLDivElement>(null);
    const { isFeatureVisible } = useFeatureFlag();

    const initialState = {
      name: "",
      upcoming_operation:
        building?.upcoming_operation || UPCOMING_OPERATION_LIST[0].id,
      construction_year: building?.construction_year
        ? building?.construction_year
        : 0,
      building_type_id: building?.building_type?.id,
      address: {
        ...building?.address,
        postal_code: building?.postal_code,
        region: building?.region,
      },
      string_address: building?.address
        ? placeDetailsToString({
            ...building.address,
            postal_code: building?.postal_code ?? "",
            region: building?.region ?? "",
          } as PlaceDetailsOUT)
        : "",
      images: [],
      ifc_parser_split_objects_list: building?.ifc_parser_split_objects_list,
    };

    const handleChange = (value: FormValue, name?: string) => {
      if (name) {
        buildingSettingsStore.updateBuildingDetails({
          ...buildingSettingsStore.buildingFormDetails,
          [name]: value,
        });
      }
      checkHasValidationsError();
    };

    function checkHasValidationsError() {
      buildingSettingsStore.setshowMandatoryAlert(
        !buildingSettingsStore.buildingFormDetails.name ||
          !buildingSettingsStore.buildingFormDetails.net_floor_area ||
          !buildingSettingsStore.buildingFormDetails.gross_floor_area ||
          !buildingSettingsStore.buildingFormDetails.conformity_id
      );
    }

    async function initialCheck() {
      await ifcMappingStore.fetchCertificationData();
      type === "add" ? initialAddRun() : initialEditRun();
      checkHasValidationsError();
    }

    useEffect(() => {
      open && (async () => await initialCheck())();
    }, [open]);

    useEffect(() => {
      type === "add" && saveAddStateToStorage();
    }, [buildingSettingsStore.buildingFormDetails]);

    function initialEditRun() {
      if (!building) return;
      buildingSettingsStore.updateBuildingDetails({
        ...building,
        address: {
          ...building?.address,
          postal_code: building?.postal_code,
          region: building?.region,
        },
        string_address: building?.address
          ? placeDetailsToString({
              ...building.address,
              postal_code: building?.postal_code ?? "",
              region: building?.region ?? "",
            } as PlaceDetailsOUT)
          : "",
      } as AddEditBuildingForm);
    }

    function initialAddRun() {
      const savedState = JSON.parse(
        localStorage.getItem("add_building") ?? "{}"
      );
      buildingSettingsStore.updateBuildingDetails({
        ...initialState,
        certification_id: ifcMappingStore.certificationOptions?.[1]?.id ?? "",
        conformity_id:
          ifcMappingStore.certificationOptions?.[1]?.conformities[0]?.id ?? "",
        ...savedState,
      });
    }

    const saveAddStateToStorage = () =>
      localStorage.setItem(
        "add_building",
        JSON.stringify({
          ...buildingSettingsStore.buildingFormDetails,
        })
      );

    function moveToSection(item: Section) {
      containerRef.current?.scrollTo({
        behavior: "smooth",
        top: Number(item.ref.current?.offsetTop) - 90,
      });
    }

    const sectionItems: Section[] = [
      {
        name: t("addBuilding.generalInformations"),
        content: <GeneralInformation handleChange={handleChange} type={type} />,
        ref: React.useRef(null),
      },
      {
        name: t("addBuilding.planned_operation_heading"),
        content: <PlannedOperation handleChange={handleChange} />,
        ref: React.useRef(null),
      },
      {
        name: t("buildingPassport.complianceSettings"),
        content: <Compliance handleChange={handleChange} type={type} />,
        ref: React.useRef(null),
      },
      ...(isFeatureVisible("ifc_import_settings")
        ? [
            {
              name: t("addBuilding.IfcSettings"),
              content: <IfcSettings handleChange={handleChange} />,
              ref: React.useRef(null),
            },
          ]
        : []),
      {
        name: t("addBuilding.mappingEditor"),
        content: <MappingEditorTile handleChange={handleChange} />,
        ref: React.useRef(null),
      },
    ];

    return (
      <Modal
        setIsOpen={close}
        isOpen={open}
        title={
          type === "add"
            ? t("addBuilding.addBuilding")
            : t("addBuilding.editBuilding")
        }
        className="mb-[70px]"
        containerClassName="max-w-full md:max-w-3xl"
        disableOutsideClick
      >
        <div
          ref={containerRef}
          className="flex max-h-[calc(100vh-210px)] overflow-y-auto overflow-x-hidden"
        >
          <div className="bg-gray-50 p-6 whitespace-nowrap text-sm font-medium text-gray-700 min-h-full sticky top-0">
            <ul>
              {sectionItems.map((item, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-indigo-50 hover:text-indigo-700 cursor-pointer"
                  onClick={() => moveToSection(item)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
          <form className="h-full">
            <div className="p-6">
              <ul>
                {sectionItems.map((item, index) => (
                  <li key={index} ref={item.ref}>
                    {item.content}
                  </li>
                ))}
              </ul>
            </div>
            <Footer
              building={building}
              onMaxLimitWarning={onMaxLimitWarning}
              type={type}
              close={close}
            />
          </form>
        </div>
      </Modal>
    );
  }
);

export default AddEditBuilding;
