import React from "react";
import { Cell } from "@tanstack/react-table";
import useWindowOverflow from "hooks/useWindowOverflow";
import { CustomFilterProps } from "components/Table/types";
import { ContextMenuState } from "components/Table/ContextMenu/useContextMenu";
import {
  IconEyeOff,
  IconFilter,
  IconFilterOff,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons-react";
import { CollectionIcon } from "@heroicons/react/outline";
import { GroupingRemoveIcon } from "../utils";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { PROPERTY_RULE_OPERATORS, SORT_OPTIONS } from "../const";
import clsx from "clsx";
import { useParams } from "react-router";

export interface ContextMenuItem {
  label: string;
  icon?: React.ReactNode;
  onClick: (cell?: Cell<unknown, unknown>) => void;
  hidden?: boolean;
}

export interface ContextMenuOption {
  title?: string;
  options: ContextMenuItem[];
}

const ContextMenu = ({
  info,
  onClose,
}: {
  info?: ContextMenuState | null;
  onClose?: () => void;
}) => {
  const { getTopPos, getLeftPos, containerRef } = useWindowOverflow();
  const { t } = useTranslation();
  const { ifc_id } = useParams();
  const cell = info?.cell;
  const columnKey = cell?.column.id;
  const property = dynamicMEStore.findPropertyByKey(columnKey);

  const addFilterRule = async () => {
    if (!property) return;
    const rules = dynamicMEStore.selectedFilterByItems.rules;
    const suggestedValues = await dynamicMEStore.fetchColumnSuggestions(
      ifc_id,
      property
    );
    rules.push({
      property: property,
      operator: PROPERTY_RULE_OPERATORS[0].id,
      value: String(cell?.getValue()),
      suggestedValues: suggestedValues ?? [],
    });
    dynamicMEStore.updateFilterRules(rules);
    setTimeout(() => dynamicMEStore.setOpenFilter(true), 500);
  };

  const removeFilterRule = () => {
    const rules = dynamicMEStore.selectedFilterByItems.rules;
    const filteredItems = rules.filter(
      (rec) => rec.property?.column_key !== columnKey
    );
    dynamicMEStore.updateFilterRules(filteredItems);
  };

  const addGroupingRule = () => {
    if (!property) return;
    dynamicMEStore.setOpenGroupBy(true);
    dynamicMEStore.setSelectedGroupByItems([
      ...dynamicMEStore.selectedGroupByItems,
      property,
    ]);
    dynamicMEStore.reOrderGroupingColumns();
  };

  const removeGroupingRule = () => {
    property && dynamicMEStore.removeGroupingRule(property?.id);
  };

  const addSortingRule = (sort: string) => {
    if (!property) return;
    dynamicMEStore.setSelectedSortedByItems([
      {
        id: String(dynamicMEStore.selectedSortedByItems.length),
        property: property,
        sort: sort,
      },
    ]);
  };

  const removeColumn = () => {
    const filteredItems = dynamicMEStore.visibleColumnProperties.filter(
      (rec) => rec.column_key != columnKey
    );
    dynamicMEStore.setVisibleColumnProperties(filteredItems);
    const propertyID = dynamicMEStore.findPropertyByKey(columnKey)?.id;
    propertyID && dynamicMEStore.removeItemFromSortList(propertyID);
  };

  const hasAnyFilterRuleForProperty = () => {
    return dynamicMEStore.selectedFilterByItems.rules.some(
      (rec) => rec.property?.column_key === columnKey
    );
  };

  const hasAnyGroupingRuleForProperty = () => {
    return dynamicMEStore.selectedGroupByItems.some(
      (rec) => rec?.column_key === columnKey
    );
  };

  const menuItems = [
    {
      title: `${t("mappingEditor.property")} “${
        (cell?.column.columnDef as CustomFilterProps)?.label?.split(" (")[0]
      }”`,
      options: [
        {
          label: t("mappingEditor.filter"),
          icon: <IconFilter width={19} strokeWidth={2.5} />,
          onClick: () => addFilterRule(),
          hidden: hasAnyFilterRuleForProperty(),
        },
        {
          label: t("mappingEditor.removeFilter"),
          icon: <IconFilterOff width={19} strokeWidth={2.5} />,
          onClick: () => removeFilterRule(),
          hidden: !hasAnyFilterRuleForProperty(),
        },
        {
          label: t("mappingEditor.group"),
          icon: <CollectionIcon width={19} strokeWidth={2.5} />,
          onClick: () => addGroupingRule(),
          hidden: hasAnyGroupingRuleForProperty(),
        },
        {
          label: t("mappingEditor.removeGroupingRules"),
          icon: <GroupingRemoveIcon />,
          onClick: () => removeGroupingRule(),
          hidden: !hasAnyGroupingRuleForProperty(),
        },
        {
          label: t("mappingEditor.sortAscending"),
          icon: <IconSortAscending width={19} strokeWidth={2.5} />,
          onClick: () => addSortingRule(SORT_OPTIONS[0].id),
        },
        {
          label: t("mappingEditor.sortDescending"),
          icon: <IconSortDescending width={19} strokeWidth={2.5} />,
          onClick: () => addSortingRule(SORT_OPTIONS[1].id),
        },
        {
          label: t("mappingEditor.hideInTable"),
          icon: <IconEyeOff width={19} strokeWidth={2.5} />,
          onClick: () => removeColumn(),
        },
      ],
    },
  ];

  if (["object_count", "mappingCell"].includes(String(columnKey))) return;
  return (
    <div
      className="absolute bg-white border border-gray-300 z-10 rounded-md text-xs whitespace-nowrap"
      style={{
        top: getTopPos(info?.y, 25),
        left: getLeftPos(info?.x, 20),
      }}
      ref={containerRef}
    >
      <ul className="list-none">
        {menuItems?.map(
          (item: ContextMenuOption, index: React.Key | null | undefined) => (
            <div key={`0_${index}`}>
              <div className="bg-gray-50 text-gray-500 px-4 py-1 border-b first-of-type:border-t-0 border-t">
                {item.title}
              </div>
              {item.options.map((rec, index) => (
                <li
                  key={index}
                  className={clsx(
                    "cursor-pointer px-4 py-2 flex items-center hover:bg-indigo-50 hover:text-indigo-700",
                    { hidden: rec.hidden }
                  )}
                  onClick={() => {
                    rec.onClick(info?.cell);
                    onClose?.();
                  }}
                >
                  {rec?.icon ? (
                    <span className="mr-1.5">{rec.icon}</span>
                  ) : null}
                  {rec.label}
                </li>
              ))}
            </div>
          )
        )}
      </ul>
    </div>
  );
};

export default ContextMenu;
